//golabal navigation
header{
	// background: #000;
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	background: transparent;
	z-index: 3;
	transition: 1s;

	@include mq-down(xs){
		background: #000;
	}

	h1{
		margin: 0;
		padding: 15px 20px;
		width: 280px;
		transition: 1s;

		@include mq-down(sm) {
			width: 200px;
		}

	}

	.navbar{
		margin: 0;
	}

	.navbar-nav{
		@include mq-down(xs){
			margin: 7.5px -15px 0;
		}
	}

	.navbar-right{
		margin: 0 !important;
	}

	.navbar-nav li {
		padding: 30px 15px;
		transition: 1s;

		@include mq-down(sm){
			padding: 20px 10px;
		}

		@include mq-down(xs){
			padding: 0;
			border-bottom: solid 1px #4e4e4e;
		}

		a{
			padding: 0;
			text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.4),
						 1px -1px 1px rgba(0, 0, 0, 0.4),
						 -1px 1px 1px rgba(0, 0, 0, 0.4),
						 -1px -1px 1px rgba(0, 0, 0, 0.4);

			&:after{
				display: block;
				width: 0;
				padding-bottom: 5px;
				content: '';
				transition: all .3s ease;
				border-bottom: 3px solid #fff;

				@include mq-down(xs) {
					display: none;
				}
			}

			&:hover{
				color: #fff;
				background:transparent;
				&:after{
					width: 100%;
					border-bottom: 3px solid #fff;

					@include mq-down(xs) {
						display: none;
					}
				}
			}

			@include mq-down(sm) {
				padding: 0;
			}

			@include mq-down(xs) {
				padding: 10px;
				opacity: 1;
			}

		}
	}

	.navbar-toggle{
		padding: 15px 10px;

		.icon-bar{
			@include mq-down(xs) {
				background: #fff;
			}
		}
	}

}

_:-ms-lang(x)::-ms-backdrop, header{
	padding: 0 20px;
}

.g-navi{
	background: rgba(0, 0, 0,0.8);
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

	@include mq-down(sm) {
		background: rgba(0, 0, 0,1);
	}

	h1{
		margin: 0;
		padding: 15px 20px;
		width: 200px;

		@include mq-down(sm) {
			width: 200px;
		}
	}

	.navbar-nav{
		li{
			padding: 16px 15px;
			@include mq-down(sm) {
				padding: 22px 10px;
			}
			@include mq-down(xs) {
				padding: 0;
			}
		}
	}

}



footer{
	background: url(../img/footer_adress_bg.png) center center no-repeat;
	height: 400px;

	.footer{
		width: 100%;
		margin: 60px auto;

		@include mq-down(xs){
			margin: 30px auto;
		}

		&__logo{
			width: 220px;
			margin:0 auto 20px;
		}

		&__title{
			@include font-size(30);

			@include mq-down(xs){
				@include font-size(18);
			}
		}

		p{
			text-align: center
		}

		.adderss{
			margin-bottom: 60px;

			@include mq-down(xs) {
				margin-bottom: 30px;
			}

		}

		.copyright{
			padding: 50px 0 0;
		}

		.list-inline{
			text-align:center;

			a{
				position: relative;
				border:solid 1px  #2D2D35;
				background: #2D2D35;
				display: block;
				@include font-size(30);
				min-width: 430px;

				@include mq-down(xs) {
					min-width: inherit;
					width: 100%;
					@include font-size(16);
				}

				&:hover{
					background:rgba(45, 45, 53,0.5);
				}
			}

			.tel{
				padding: 20px 50px 20px 75px;

				@include mq-down(xs){
					display: block;
					padding: 15px 25px 15px 50px;
					margin-bottom: 20px;
				}

				&:before{
					content: "";
					background: url(../img/icon_tel.svg) left top no-repeat;
					width: 46px;
					height: 34px;
					background-size: contain;
					position: absolute;
					left: 101px;
					top: 25px;

					@include mq-down(xs){
						width: 20px;
						left: 25px;
						top: 18px;
					}
				}
			}

			.mail{
				padding: 20px 23px 20px 75px;

				@include mq-down(xs){
					padding: 15px 23px 15px 67px;
				}

				&:before{
					content: "";
					background: url(../img/icon_mail.svg) left top no-repeat;
					width: 46px;
					height: 34px;
					background-size: contain;
					position: absolute;
					left: 22px;
					top: 27px;

					@include mq-down(xs){
						width: 36px;
						left: 22px;
						top: 17px;
					}

				}
			}

		}

	}
}
