//KaoruDesigin
//animation css
//ver 0.1
//アニメーション
@keyframes slideRight {
	0% {
		opacity: 0;
		left: 30px;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes turn {
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes leftright {
    0% {
		width: 0;
		opacity: 0;
    }
    100% {
		width: 83.3%;
		opacity: 1;
    }
}

@keyframes scrollLine {
	from {
		transform: translateY(0);
		height: 0;
	}
	to {
		transform: translateY(100%);
		height: 70px;
	}
}

@keyframes scrollup {
	0% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	15% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	30% {
		transform: scale(1, 0);
		transform-origin: 100% 100%;
	}
	45% {
		transform: scale(1, 1);
		transform-origin: 100% 100%;
	}
	100% {
		transform: scale(1, 1);
		transform-origin: 100% 100%;
	}
}

@keyframes scrolldown {
	0% {
		transform: scale(1, 1);
		transform-origin: 100% 100%;
	}
	33% {
		transform: scale(1, 0);
		transform-origin: 100% 100%;
	}
	66% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	100% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
}

@keyframes lightSpeedIn {
	0% {
		-webkit-transform:translate3d(100%,0,0)skewX(-30deg);
		transform:translate3d(100%,0,0)skewX(-30deg);
		opacity:0;
	}
	60% {
		-webkit-transform:skewX(20deg);
		transform:skewX(20deg);
		opacity:1;
	}
	80% {
		-webkit-transform:skewX(-5deg);
		transform:skewX(-5deg);
		opacity:1;
	}
	100% {
		-webkit-transform:none;
		transform:none;
		opacity:1;
	}
}

@keyframes showBlock1 {
	0%, 20% {
		left: 101%;
	}
	100%, 90% {
		left: 205%;
	}
}

@keyframes showBlock1 {
	0%, 20% {
		left: 101%;
	}
	100%, 90% {
		left: 205%;
	}
}

@keyframes showBlock2 {
	0% {
		left: 0;
	}
	50% {
		left: 101%;
	}
	100% {
		left: 205%;
	}
}

@keyframes showTxt {
	0%, 50% {
		opacity: 0;
	}
	100%, 51% {
		opacity: 1;
	}
}


@keyframes slide_img_on {
	0% { transform:scale(1.16); }
	100% { transform:scale(1); }
}

.slide_img_on { animation:slide_img_on 15s linear infinite; }


.fadeIn__img {
	opacity: 0;
	animation: fadeIn 1.4s ease-out both;
}

.application-img{
	opacity: 0;
	position: relative;
	animation: slideRight 1000ms ease both;
}

.case01{
	animation: turn 0.5s ease 0.5s 1 forwards;
}

.case02{
	animation: turn 0.5s ease 1s 1 forwards;
}

.case03{
	animation: turn 0.5s ease 1.5s 1 forwards;
}

.case04{
	animation: turn 0.5s ease 2s 1 forwards;
}

.lightSpeedIn {
	animation-name:lightSpeedIn;
	animation-timing-function:ease-out;
}

.blockAnime{
	display: inline-block;
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		background: #fff;
		z-index: 2;
		transform: translate3d(-101%, 0, 0);
		transition: .3s all .1s cubic-bezier(0, 1.07, 1, 1);
		animation:showBlock1 1s cubic-bezier(0, 1.07, 1, 1) 0s forwards;
		animation:showBlock2 1s cubic-bezier(0, 1.07, 1, 1) .5s forwards,
				hideBlock2 1s cubic-bezier(0, 1.07, 1, 1) 4.8s forwards;
	}
}

.blockAnime_inner {
	opacity: 0;
	animation: showTxt 1s linear .5s forwards, hideTxt 1s linear 4.8s forwards;
}
