//KaoruDesigin
//btn.scss
//ver 0.1

a,a img{
	transition: all  0.5s ease;
	color: $white;
}

a:hover img{
	opacity: .5;
	filter: alpha(opacity=50);
	transition: opacity 1s ease-out;
}

a:link,a:visited,a:active,a:hover{
	overflow: hidden;
	outline: none;
}

button[type="more"],
.more{
	display: block;
	width: 154px;
	background: none;
	border:none;
	position: relative;
	margin: 0 auto;
	// z-index: -1;

	span{
		position: absolute;
		display: inline-block;
		width: 119px;
		height: 2px;
		background-color: #fff;
		margin-left: -85px;
		top: 33px;
		transition: transform .5s ease-in-out;
		padding-right: 50px;

		@include mq-down(sm) {
			width: 127px;
			margin-left: -100px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: #fff;
			width: 100%;
			height: 2px;
			z-index: 2;
		}

		&:after{
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 19px;
			right: -2px;
			bottom: 1px;
			background-color: #fff;
			transform-origin: right bottom;
			transform: rotate(-45deg);
		}

	}
}

//Page TOP
#pageTop {
	position: fixed;
	bottom: 20px;
	right: 20px;

	a {
		display: block;
		z-index: 999;
		padding: 8px 0 0 8px;
		border-radius: 30px;
		width: 35px;
		height: 35px;
		background-color: #9FD6D2;
		color: #fff;
		font-weight: bold;
		text-decoration: none;
		text-align: center;

		&:hover {
			text-decoration: none;
			opacity: 0.7;
		}
	}
}
