//KaoruDesigin
//template css
//ver 0.1
@import "setting";
@import "animation";
@import "btn";
@import "golbalnav";
//共有
body{
	@include font-size(16);
	line-height: 1.8;
	font-family: $font-noto;
	color: $white;
	background: $black;

	a{
		transition: all 0.5s ease;
		&:hover{
			text-decoration: none;
			color: #fff;
		}
		&:focus{
			color: #fff;
		}
	}
	.title{
		font-family: $font-en;
	}
	img{
		display: block;
		height: auto;
		max-width: 100%;
	}

	.fixed{
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10000;
	}

	.nobreak{
		 span {
			display: inline-block;
			white-space: nowrap;
		}
	}

	.switch {
		visibility: hidden;
	}

	.hd-en{
		font-family: $font-en;
		@include font-size(50);
		line-height: font-height(12,16);
		letter-spacing: 6px;
		margin:0 0 20px;

		@include mq-down(sm) {
			@include font-size(36);
			line-height: font-height(12,16);
			letter-spacing: 0;
		}

		span{
			font-family: $font-noto;
			@include font-size(18);

			&:before{
				content: "\A";
				white-space: pre;
			}
		}
	}

	.sub-lead{
		padding:60px 0;

		@include mq-down(xs) {
			padding:50px 0;
		}

		&__title{
			@extend .lead-tc;
			@include font-size(36);
			margin: 0;
			letter-spacing: 6px;
			line-height: 1.6;

			@include mq-down(sm) {
				@include font-size(24);
				letter-spacing: 3px;
				line-height: 1.2;
			}

			br{
				@include mq-down(sm) {
					display: none;
				}
			}
		}

		&__lead{
			@extend .lead-tc;
			margin: 30px 0 0;
			line-height: 2.0;
			letter-spacing: 4px;

			@include mq-down(sm) {
				line-height: 1.8;
				margin: 10px 0 0;
				letter-spacing: 0;
			}

			br{
				@include mq-down(sm) {
					display: none;
				}
			}
		}
	}

	.sub-eyecatch{
		position: relative;
		width: 100%;
		height: 100%;

		&:before{
			content: '';
			display: block;
			padding-top: 27%;/*画像の幅÷高さ×100*/

			@include mq-down(sm) {
				margin-top: 63px;
				padding-top: 35%;/*画像の幅÷高さ×100*/
			}
		}

		&__img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;

			&__title{
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%, -50%);
				text-align: center;
				font-weight: 700;
				letter-spacing: 10px;
				@include font-size(60);
				text-shadow:10px 5px 31px rgb(4, 16, 24),10px -7px 26px rgb(4, 16, 24),-22px 7px 26px rgb(4, 16, 24),1px 0px 6px rgb(4, 16, 24);
				@include mq-down(sm) {
					@include font-size(28);
					white-space: nowrap;
					line-height: 1.1;
					left:55%;
				}
				span{
					font-weight: 500;
				}
			}
		}
	}

	.img_hover{
		position: relative;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			transition: .5s ease-in-out;
			opacity: 0;
			background: #00baff;
			background: linear-gradient(to right, rgba(0, 186, 255, 0.5) 0%, rgba(146, 112, 255, 0.5) 100%);
			background-size: 100% !important;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00baff', endColorstr='#9270ff',GradientType=1 );
			z-index: 3;
		}

		&:hover:before {
			opacity: 1;
		}
	}

	.footer_contents{
		margin-bottom: 120px;
		h3{
			border-top:solid 1px #898989;
			border-bottom:solid 1px #898989;
			text-align:center;
			padding: 20px 0;
			margin-bottom: 60px;

			@include mq-down(xs){
				margin-top: 60px;
			}
		}

		&__products{
			background:url(../img/conteonts_footer_bnr01.png) center center no-repeat;
		}

		&__company{
			background:url(../img/conteonts_footer_bnr02.png) center center no-repeat;
		}

		&__techno{
			background:url(../img/conteonts_footer_bnr03.png) center center no-repeat;
		}

		.link-box{
			box-shadow: 0 0 0 10px rgba(255, 255, 255,0.5) inset;
			box-sizing: border-box;
			background-size: cover;
			min-height: 260px;
			position: relative;
			@include mq-down(xs){
				margin-bottom: 30px;
			}
			&__item{
				width: 170px;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%, -50%);
				text-align:center;
			}
		}
	}
}
